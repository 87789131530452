import gql from "graphql-tag";
import { buildFields } from "ra-data-hasura";
import { BuildFields, FetchType } from "ra-data-hasura";
import { ResourceType } from "../constants";

/**
 * Extracts just the fields from a GraphQL AST.
 * @param {GraphQL AST} queryAst
 */
const extractFieldsFromQuery = (queryAst: any) => {
  return queryAst.definitions[0].selectionSet.selections;
};

const GET_ONE_OR_LIST_CAMPAIGN = gql`
  {
    id
    name
    product_name
    budget_cash_usd_cents
    budget_pik_usd_cents
    brand_name
    description
    start_date
    end_date
    recruitment_end_date
    client_profile {
      id
      company_name
    }
    images {
      file_metadata {
        id
        url
        content_type
      }
    }
    tranches {
      min_follower_size
      max_follower_size
      number_kols
      cash_usd_cents
      product_value_usd_cents
      requirements
      product_categories {
        category_code
        product_category {
          name
        }
      }
      social_platforms {
        category_code
        social_platform {
          name
        }
      }
      genders {
        category_code
        gender {
          code
          description
        }
      }
      age_groups {
        category_code
        age_category {
          code
          name
        }
      }
      deliverable_specs {
        content_type {
          name
          code
        }
        quantity
        due_date
        requirement_description
        title
      }
    }
  }
`;

const GET_ONE_OR_LIST_KOL_PAYOUT = gql`
  {
    id
    kol_profile_id
    total_amount
    user_id
    wallet_id
    kol_profile {
      individual_fullname
    }
  }
`;

const GET_ONE_OR_LIST_CLIENT_PROFILE = gql`
  {
    address
    address2
    company_name
    country_code
    created_at
    description
    display_currency
    id
    is_active
    is_approved
    logo_file_id
    phone_number
    postal_code
    region
    region2
    updated_at
    user_id
    user {
      email
      given_name
      family_name
    }
    wallet_id
    wallet {
      id
      amount_usd_cents
    }
    logo_file {
      id
      url
    }
    client_survey {
      additional_info
      avg_budget_per_campaign_usd_cents
      avg_kols_per_campaign
      client_profile_id
      created_at
      display_currency
      id
      is_currently_running_campaigns
      number_of_campaigns_annually
      updated_at
    }
  }
`;

const GET_ONE_OR_LIST_KOL_PROFILE = gql`
  {
    id
    address
    address_city
    address_postal_code
    address_state
    bank_account_no
    bank_address
    bank_branch_full_address
    bank_city
    bank_country
    bank_name
    bank_state
    bank_swift_code
    bio
    company_name
    company_registration_no
    country_code
    cpe_rate
    created_at
    date_of_birth
    display_currency
    email
    engagement_rate
    entity_type
    first_name
    follower_size
    gender
    individual_fullname
    individual_id_no
    individual_passport_no
    is_active
    is_approved
    last_name
    min_rate_photo_usd_cents
    min_rate_video_usd_cents
    mobile_number
    occupation
    past_brands_collaboration
    updated_at
    user_id
    wallet_id
    wallet {
      id
      amount_usd_cents
    }
    mobile_country_code
    bank_verification_status
    is_company_bank_account
    bank_company_full_name
    bank_business_registration_number
    social_platforms {
      social_platform_code
      platform_username
    }
    product_categories {
      interest_code
      product_category {
        name
      }
    }
    mapping_kol_profile_languages {
      language {
        title
        code
      }
    }
    kol_file_metadata {
      type
      file_metadata {
        id
        url
        original_name
        owner_id
      }
      kol_profile_id
      created_at
    }
  }
`;

export const UPDATE_ONE_KOL_PROFILE = gql`
  mutation update_kol_profile($id: uuid!, $bank_verification_status: String) {
    update_kol_profile(
      where: { id: { _eq: $id } }
      _set: { bank_verification_status: $bank_verification_status }
    ) {
      returning {
        id
      }
      affected_rows
    }
  }
`;

export const customBuildFields: BuildFields = (type, fetchType) => {
  const resourceName = type.name;

  console.info(`[customBuildFields][${resourceName}]`, { resourceName, type });

  if (
    resourceName === ResourceType.kol_payout &&
    [FetchType.GET_LIST, FetchType.GET_ONE].includes(fetchType as FetchType)
  ) {
    return extractFieldsFromQuery(GET_ONE_OR_LIST_KOL_PAYOUT);
  }

  if (
    resourceName === ResourceType.kol_profile &&
    [FetchType.GET_LIST, FetchType.GET_ONE].includes(fetchType as FetchType)
  ) {
    return extractFieldsFromQuery(GET_ONE_OR_LIST_KOL_PROFILE);
  }

  if (
    resourceName === ResourceType.kol_profile &&
    fetchType === FetchType.UPDATE
  ) {
    // TODO: This not working now, when used conjunction with, find out why
    // const { save, record } = useEditController({
    //   resource: "kol_profile",
    //   id: params.id,
    // });
    return extractFieldsFromQuery(UPDATE_ONE_KOL_PROFILE);
  }

  if (
    resourceName === ResourceType.client_profile &&
    [FetchType.GET_LIST, FetchType.GET_ONE].includes(fetchType as FetchType)
  ) {
    return extractFieldsFromQuery(GET_ONE_OR_LIST_CLIENT_PROFILE);
  }

  if (
    resourceName === ResourceType.campaign &&
    [FetchType.GET_LIST, FetchType.GET_ONE].includes(fetchType as FetchType)
  ) {
    return extractFieldsFromQuery(GET_ONE_OR_LIST_CAMPAIGN);
  }

  // No custom query defined, so use the default query fields (all, but no related/nested).
  return buildFields(type, fetchType);
};
